import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteRestaurant = createAsyncThunk(
  "admin/restaurant-delete",
  async ({ restaurantID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.restaurantDetailsURL.replace("{id}", restaurantID),
        method: "DELETE",
      });
      let data = await response;
      if (response.status === 204) {
        let result = response.data;
        return result;
      } else  {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const restaurantsDeleteSlice = createSlice({
  name: "restaurant-delete",
  initialState: {
    deleteRestaurantFetching: false,
    deleteRestaurantSuccess: false,
    deleteRestaurantError: false,
    deleteRestaurantErrorMessage: "",
  },
  reducers: {
    clearDeleteRestaurantState: (state) => {
      state.deleteRestaurantError = false;
      state.deleteRestaurantSuccess = false;
      state.deleteRestaurantFetching = false;
      state.deleteRestaurantErrorMessage = "";

      return state;
    },
  },
  extraReducers: {
    [deleteRestaurant.fulfilled]: (state, { payload }) => {
      state.deleteRestaurantFetching = false;
      state.deleteRestaurantSuccess = true;
      return state;
    },
    [deleteRestaurant.rejected]: (state, action) => {
      state.deleteRestaurantFetching = false;
      state.deleteRestaurantError = true;
      state.deleteRestaurantErrorMessage = action?.payload;
    },
    [deleteRestaurant.pending]: (state) => {
      state.deleteRestaurantFetching = true;
    },
  },
});
export const { clearDeleteRestaurantState } = restaurantsDeleteSlice.actions;

export default restaurantsDeleteSlice.reducer;
