import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const addRestaurant = createAsyncThunk(
  "admin/restaurant-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.restaurantPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const restaurantsAddSlice = createSlice({
  name: "restaurant-add",
  initialState: {
    createRestaurantFetching: false,
    createRestaurantSuccess: false,
    createRestaurantError: false,
    createRestaurantErrorMessage: "",
  },
  reducers: {
    clearCreateRestaurantState: (state) => {
      state.createRestaurantError = false;
      state.createRestaurantSuccess = false;
      state.createRestaurantFetching = false;
      state.createRestaurantErrorMessage = "";

      return state;
    },
  },
  extraReducers: {
    [addRestaurant.fulfilled]: (state, { payload }) => {
      state.createRestaurantFetching = false;
      state.createRestaurantSuccess = true;
      return state;
    },
    [addRestaurant.rejected]: (state, action) => {
      state.createRestaurantFetching = false;
      state.createRestaurantError = true;
      state.createRestaurantErrorMessage = action?.payload;
    },
    [addRestaurant.pending]: (state) => {
      state.createRestaurantFetching = true;
    },
  },
});

export const { clearCreateRestaurantState } = restaurantsAddSlice.actions;

export default restaurantsAddSlice.reducer;
