import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";

//Login
import userSlice from "./login/loginSlice";

//Restaurants
import restaurantsAddSlice from "./restaurants/restaurantsAddSlice";
import restaurantsListSlice from "./restaurants/restaurantsListSlice";
import restaurantsDetailsSlice from "./restaurants/restaurantsDetailsSlice";
import restaurantsEditSlice from "./restaurants/restaurantsEditSlice";
import restaurantsDeleteSlice from "./restaurants/restaurantsDeleteSlice";

//Business Details
import businessDetailsSlice from "./systemSettings/business/businessDetailsListSlice";
import businessEditSlice from "./systemSettings/business/businessDetailsEditSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
    userSlice,
    //Restaurants
    restaurantsAddSlice,
    restaurantsListSlice,
    restaurantsDetailsSlice,
    restaurantsEditSlice,
    restaurantsDeleteSlice,
    //Settings
    businessDetailsSlice,
    businessEditSlice,
  },
});
