import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";


//Restaurants
const Restaurants = lazy(() => import("../views/Restaurants/Restaurants"));

//Settings
const Settings = lazy(() => import("../views/settings/index"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={200}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/restaurants" exact component={Restaurants} />            
            <Route path="/settings/:active_tab?" component={Settings} />  
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
